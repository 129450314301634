import React, { useState, SyntheticEvent, useEffect, ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Form, Logo, Text, BlockLink } from 'pws-design-system/design-system';
import Card from '../../ui/Card';
import { DefaultEase } from '../../../types/constants';

const MotionBox = motion.custom(Box);

export enum FormState {
    initial = 'initial',
    submitting = 'submitting',
    failed = 'failed',
    success = 'success'
}

interface AuthFormViewProps {
    data?: any;
    errors?: any;
    headerElement?: any;
    onStateChange?: (state: FormState) => void;
    onSubmit?: (data: any) => void;
    children: ReactNode | Array<ReactNode>;
}

const AuthFormView = ({
    data = {},
    errors: initialErrors = null,
    headerElement,
    onStateChange = () => {},
    onSubmit = () => {},
    children,
    ...rest
}: AuthFormViewProps) => {
    const [currentState, setCurrentState] = useState(FormState.initial);
    const [errors, setErrors] = useState(initialErrors);

    if (initialErrors !== errors) {
        setErrors(initialErrors);
    }

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        const target = e.target as HTMLFormElement;
        const formData = new FormData(target);
        formData.append('submit', '');
        setErrors(null);
        setCurrentState(FormState.submitting);

        const obj: any = {};
        formData.forEach((value: any, key: string) => {
            obj[key] = value;
        });
        onSubmit(obj);
    };

    useEffect(() => {
        onStateChange(currentState);
    }, [currentState]);

    return (
        <AnimatePresence>
            <Box maxWidth={[null, null, '500px']} my={[5, null, 6]} mx={[3, null, 'auto']} {...rest}>
                <BlockLink href={`${process.env.GATSBY_PWS_WEBSITE_URL}`}>
                    <Logo
                        variantColor="light"
                        height={['60px', null, '80px']}
                        mb={[4, null, 6]}
                        mx="auto"
                    />
                </BlockLink>
                <MotionBox
                    key="auth-login"
                    maxWidth="500px"
                    margin="0 auto"
                    my={[5, null, 6]}
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -50, opacity: 0 }}
                    transition={{
                        ease: DefaultEase,
                        duration: 0.3,
                    }}
                >
                    <Card>
                        {headerElement}
                        <Form data={data} method="post" onSubmit={handleSubmit}>
                            {errors && (
                                <Box bg="red.100" p={2} mb={3} mx={-2} rounded="md">
                                    <Text variant="caption1" color="text.error">
                                        {errors}
                                    </Text>
                                </Box>
                            )}
                            {children}
                        </Form>
                    </Card>
                </MotionBox>
            </Box>
        </AnimatePresence>
    );
};

export default AuthFormView;
