import React, { useEffect } from 'react';
import { navigate, Link as GatsbyLink } from 'gatsby';
import { Box, Button, ButtonLink, FormField, Icon, Link, Stack, Text } from 'pws-design-system/design-system';
import { useLocation } from '@reach/router';
import Layout from '../../layouts/Layout';
import AuthFormView from '../../views/auth/AuthFormView';
import Api from '../../../api/Api';
import useApi from '../../hooks/api/useApi';
import User from '../../../models/users/User';

const LoginPage = () => {
    const location = useLocation();
    const { request: login, result, error, isLoading } = useApi<User>((api: Api, data: any) => (
        api.routes.auth.login(data.email, data.password)
    ));

    async function handleSubmit(data: any) {
        login({ email: data.email, password: data.password });
    }

    useEffect(() => {
        if (result.success) {
            let pathname = '/';
            const locationState: any = location && location.state ? location.state : {};
            if (locationState.referrer) {
                const { referrer } = locationState;
                if (referrer.pathname) {
                    pathname = referrer.pathname;
                }
            }
            navigate(pathname, { replace: true });
        }
    }, [result]);

    return (
        <Layout>
            <AuthFormView errors={error} onSubmit={handleSubmit}>
                <Stack spacing={4}>
                    <FormField
                        name="email"
                        label="Email"
                        placeholder=""
                        // validate={{ regex: /^[a-zA-Z]/, message: 'Invalid value' }}
                        field={{
                            type: 'text',
                            variant: 'flushed',
                            rightElement: <Icon name="email" color="gray.300" />,
                        }}
                        required
                    />
                    <FormField
                        name="password"
                        label="Password"
                        placeholder=""
                        // validate={{ regex: /^[a-zA-Z]+$/, message: 'Invalid value' }}
                        field={{
                            type: 'password',
                            variant: 'flushed',
                            rightElement: <Icon name="lock" color="gray.300" />,
                        }}
                        required
                    />
                    <Text mt={-3} variant="caption2" textAlign="right" color="text.base.tertiary">
                        <Link as={GatsbyLink} to="/forgot-password">
                            Forgot password?
                        </Link>
                    </Text>
                    <Stack spacing={3}>
                        <Button variantColor="brand-blue" type="submit" isLoading={isLoading}>
                            Log In
                        </Button>
                        <ButtonLink size="sm" variant="link" variantColor="light" href={`${process.env.GATSBY_PWS_WEBSITE_URL}/register`}>
                            Create an Account
                        </ButtonLink>
                    </Stack>
                    <Box textAlign="center">
                        <Text variant="footnote" color="bg.500">
                            <Link
                                href={`${process.env.GATSBY_PWS_WEBSITE_URL}/terms-and-conditions`}
                            >
                                Terms
                            </Link>
                            {' | '}
                            <Link href={`${process.env.GATSBY_PWS_WEBSITE_URL}/privacy-policy`}>
                                Privacy
                            </Link>
                        </Text>
                    </Box>
                </Stack>
            </AuthFormView>
        </Layout>
    );
};

export default LoginPage;
