import React from 'react';
import { Heading, Surface } from 'pws-design-system/design-system';
import { PseudoBoxProps } from 'pws-design-system/design-system/components/box/Box';

interface CardProps extends PseudoBoxProps {
    children?: any;
}

const Card = ({ children, ...rest }: CardProps) => (
    <Surface
        elevation={3}
        total={4}
        boxShadow="sm"
        mb={3}
        rounded={['lg', null, null, 'xl']}
        p={[3, null, null, 4]}
        {...rest}
    >
        {children}
    </Surface>
);

export const CardHeading = (props: any) => (
    <Heading as="h2" fontSize="3xl" mb={3} mt={[2, null, null, 0]} {...props} />
);

export default Card;
